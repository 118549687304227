import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import { VARIANTS } from 'contexts/SnackbarContext';
import useSnackbars from 'hooks/useSnackbar';
import { languagesIconPath } from 'layouts/NavbarElements/Language';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface LanguagePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { i18n, t } = useTranslation();
  const { addSnackbar } = useSnackbars();
  const languageOptions: { [key: string]: { icon: string; label: string } } = {
    en: {
      icon: languagesIconPath.en,
      label: t('layout.language.en')
    },
    es: {
      icon: languagesIconPath.es,
      label: t('layout.language.es')
    }
  };
  const handleChange = (language: string): void => {
    onClose?.();
    i18n.changeLanguage(language);
    addSnackbar(VARIANTS.success, t('layout.language.languageChanged'));
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 240 } }}
      transitionDuration={0}
      {...other}
    >
      {Object.keys(languageOptions).map((language) => {
        if (language === i18n.language) {
          return null;
        }

        return (
          <MenuItem onClick={() => handleChange(language)} key={language}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        );
      })}
    </Popover>
  );
};
