import { useUser } from 'contexts/UserContext';
import { useEffect } from 'react';
import { getDecodeToken } from 'shared/utils/functions';

/**
 * Hook to set the user context when the user is already logged in
 */
export const useUserContext = () => {
  const user = useUser();
  useEffect(() => {
    const decodedToken = getDecodeToken();
    if (decodedToken) {
      const currentUser: AuthUser = {
        user_id: decodedToken.user_id,
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        email: decodedToken.email,
        groups: decodedToken.groups?.map((group: any) => group.name)
      };
      user?.setCurrentUser(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
