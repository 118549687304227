import {
  Briefcase as BriefcaseIcon,
  Home as HomeIcon,
  LayoutRoot,
  Navbar,
  Reports as ReprotsIcon,
  Section,
  Sidebar,
  SidebarSection,
  Users as UsersIcon
} from '@inappsis/react-inappsis-ui';
import { Box, Chip, Toolbar, Typography } from '@mui/material';
import { AccountButton, LanguageButton } from 'layouts/NavbarElements';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Logo } from './Logo';

interface LayoutProps {
  children?: ReactNode;
}

const getSections = (t: TFunction): Section[] => [
  {
    title: t('layout.sections.general'),
    items: [
      {
        title: t('layout.generalSection.dashboard'),
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: t('layout.generalSection.sales'),
        path: '/services',
        icon: <BriefcaseIcon fontSize="small" />,
        chip: (
          <Chip
            color="secondary"
            label={
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600'
                }}
              >
                NEW
              </Typography>
            }
            size="small"
          />
        )
      },
      {
        title: t('layout.generalSection.purchases'),
        path: '/services',
        icon: <BriefcaseIcon fontSize="small" />,
        chip: (
          <Chip
            color="secondary"
            label={
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600'
                }}
              >
                NEW
              </Typography>
            }
            size="small"
          />
        )
      },
      {
        title: t('layout.generalSection.consignaciones'),
        path: '/services',
        icon: <BriefcaseIcon fontSize="small" />,
        chip: (
          <Chip
            color="secondary"
            label={
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600'
                }}
              >
                NEW
              </Typography>
            }
            size="small"
          />
        )
      },
      {
        title: t('layout.generalSection.services'),
        path: '/services',
        icon: <BriefcaseIcon fontSize="small" />,
        chip: (
          <Chip
            color="secondary"
            label={
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600'
                }}
              >
                NEW
              </Typography>
            }
            size="small"
          />
        )
      }
    ]
  },
  {
    title: t('layout.sections.management'),
    items: [
      {
        title: t('layout.managementSection.vehicles'),
        path: '/vehicles',
        icon: <UsersIcon fontSize="small" />
      },
      {
        title: t('layout.managementSection.clients'),
        path: '/clients',
        icon: <UsersIcon fontSize="small" />
      }
    ]
  },
  {
    title: t('layout.sections.reports'),
    items: [
      {
        title: t('layout.generalSection.reports'),
        path: '/reports',
        icon: <ReprotsIcon fontSize="small" />
      }
    ]
  }
];

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();
  const sections = useMemo(() => getSections(t), [t]);

  return (
    <>
      <LayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
        </Box>
      </LayoutRoot>
      <Navbar onOpenSidebar={(): void => setIsSidebarOpen(true)}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          {/* Others navbar buttons should be added here */}
          <LanguageButton />
          <AccountButton />
        </Toolbar>
      </Navbar>
      <Sidebar
        logo={
          <Logo
            sx={{
              maxHeight: 120,
              maxWidth: '100%'
            }}
          />
        }
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      >
        {sections.map((section) => (
          <SidebarSection
            key={section.title}
            path={location.pathname}
            sx={{
              mt: 2,
              '& + &': {
                mt: 2
              }
            }}
            {...section}
          />
        ))}
      </Sidebar>
    </>
  );
};
