import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './shared/translations/english.json';
import spanishTranslations from './shared/translations/spanish.json';

const resources = {
  en: {
    translation: englishTranslations
  },
  es: {
    translation: spanishTranslations
  }
};

dayjs.locale('es');

use(initReactI18next).init({
  resources,
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
  }
});
