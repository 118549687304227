import { useUserContext } from 'hooks/useUserContext';
import { FC, Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'shared/components/Auth/AuthGuard';
import GuestAuthGuard from 'shared/components/Auth/GuestAuthGuard';
import { Layout as MainLayout } from './layouts/Layout';
import { GuestGuard, LoadingScreen, RolesGuard } from './shared/components';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: FC;
  layout?: FC;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    component: () => <Navigate to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/NotFound'))
  },
  {
    exact: true,
    path: '/politica',
    component: lazy(() => import('./views/pages/Public/Politica'))
  },
  {
    exact: true,
    path: '/unsuscribe',
    component: lazy(() => import('./views/pages/Public/Unsuscribe'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/pages/Authentication/Login'))
  },
  {
    exact: true,
    guard: GuestAuthGuard,
    path: '/logout',
    component: lazy(() => import('./views/pages/Authentication/Logout/index'))
  },
  {
    path: '/vehicles/*',
    guard: RolesGuard(['admin']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Vehicles/VehicleList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Vehicles/Form/VehicleForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Vehicles/Form/VehicleForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Vehicles/Details/VehicleDetails')
        )
      }
    ]
  },
  {
    path: '/clients/*',
    guard: RolesGuard(['admin']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Clients/ClientList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Clients/Form/ClientForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Clients/Form/ClientForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Clients/Details/ClientDetails')
        )
      }
    ]
  },
  {
    path: '/services/*',
    guard: RolesGuard(['admin']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Services/ServiceList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Services/Form/ServiceForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Services/Form/ServiceForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Services/Details/ServiceDetails')
        )
      }
    ]
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import('./views/pages/Dashboard'))
  },
  {
    exact: true,
    path: '/reports',
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import('./views/pages/Reports/Reports'))
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Navigate to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path ?? ''}
              element={
                <Guard>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Component />}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;

const AppRoutes = () => {
  useUserContext();
  return renderRoutes(routesConfig);
};

export default AppRoutes;
